import logo from '../assets/logo.png';
import bgWhite from '../assets/bgWhite.png';
import profile from '../assets/profile.png';

import headerCircle from '../assets/circle2.png';
import awslogo from '../assets/aws-logo.png';
import githublogo from '../assets/github-logo.png';
import javalogo from '../assets/java-logo.png';
import javascriptlogo from '../assets/javascript-logo.png';
import reactlogo from '../assets/react-logo.png';
import kuberneteslogo from '../assets/kubernetes-logo.png';
import dockerlogo from '../assets/docker-logo.png';
import bgIMG from '../assets/bgIMG.png';
import mobile from '../assets/mobile.png';
import email from '../assets/email.png';

export default {
    logo,
    bgWhite,
    profile,
    headerCircle,
    awslogo,
    githublogo,
    javalogo,
    javascriptlogo,
    reactlogo,
    kuberneteslogo,
    dockerlogo,
    bgIMG,
    mobile,
    email
};