import React, { useState, useEffect } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
// import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Skills.scss';

const Skills = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [testimonials, setTestimonials] = useState([]);
 
  
    const handleClick = (index) => {
      setCurrentIndex(index);
    };
  
    useEffect(() => {
      const query = '*[_type == "testimonials"]';

  
      client.fetch(query).then((data) => {
        setTestimonials(data);
      });
    }, []);
  
    return (
      <>
      <h2 className="head-text"><span>Skills</span></h2>
        {testimonials.length && (
          <>
            <div className="skills_item app__flex">
              <img src={urlFor(testimonials[currentIndex].imgurl)} alt={testimonials[currentIndex].name} />
              <div className="skills_content">
                <p className="p-text">{testimonials[currentIndex].feedback}</p>
                <div>
                  <h4 className="bold-text">{testimonials[currentIndex].name}</h4>
                  <h5 className="p-text">{testimonials[currentIndex].company}</h5>
                </div>
              </div>
            </div>
  
            <div className="skills_btns app__flex">
              <div className="app__flex" onClick={() => handleClick(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1)}>
                <HiChevronLeft />
              </div>
  
              <div className="app__flex" onClick={() => handleClick(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1)}>
                <HiChevronRight />
              </div>
            </div>
          </>
        )}
      </>
    );
  };

export default AppWrap(
    MotionWrap(Skills, 'app__skills'),
    'skills',
    'app__primarybg',
  );
