import React, { useState, useEffect } from 'react';
import { easeInOut, motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import { Popup } from '../../components';
import './Experience.scss';

const Experience = () => {
  const [experiences, setExperiences] = useState([]);
  // const [skills, setSkills] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';

    client.fetch(query).then((data) => {
      setExperiences(data);
    });

  }, []);

  return (
    <>
      <h2 className="head-text"><span>Work Experience</span></h2>

      <div className="experience_container">
        <div className="experience">
          {experiences.map((experience, index) => (
            <motion.div
              className="experience_item"
              // key={experience.year}
              key={`${experience.name}_${index}`}
            >
              <div className="experience_year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="experience_works">
                {experience.works.map((work, index) => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="experience_work"
                      key={`${work.name}+${index}`}
                    >
                      <Popup title={


                        <motion.div
                          whileInView={{
                            textShadow: [
                              "0 0 0px rgba(0, 0, 0, 0)",
                              "0 0 10px rgba(0, 13, 255, 0.75)",
                              "0 0 20px rgba(0, 13, 255, 0.75)"
                            ],
                            scale:[1,1.1,1],
                            transition: {
                              duration: 8,
                              repeat: Infinity,
                              repeatType: "reverse",
                              ease:easeInOut
                            }
                          }}
                        >
                          <span className='work-title'>{work.name}</span>
                        </motion.div>

                      } workDescription={<span className='work-description'>{work.desc}</span>} />
                      <p className="p-text"><span className='work-company'>{work.company}</span></p>
                    </motion.div>
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Experience, 'app__experience'),
  'experience',
  'app__whitebg',
);